import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export default new class {
  init() {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_CLIENT_KEY,
      plugins: [new BugsnagPluginVue()],
      appVersion: import.meta.env.VITE_APP_VERSION,
      releaseStage: import.meta.env.VITE_APP_ENV,
      enabledReleaseStages: ['prod', 'prod-secondary', 'staging', 'staging-secondary', 'demo', 'dev', 'client-squad', 'money-squad', 'provider-squad', 'hooli-squad', 'credentialing-squad', 'employee-sandbox'],
      user: !window.user ? null : {
        id: window.user.id,
        name: `${window.user.first_name} ${window.user.last_name}`,
        email: window.user.email,
      },
      onError(event) {
        const statusCode = event.originalError?.response?.status;
        const message = event.originalError?.message;
        // Don't send events for "Unauthenticated" responses (401).
        // Don't send events for "Unprocessable Entity" responses (422).
        // Don't send events for "Authentication Timeout" responses (419).
        const excludedStatuses = [401, 419, 422];
        const excludedMessages = ['Network Error'];
        if ((statusCode && excludedStatuses.includes(statusCode))
          || (message && excludedMessages.includes(message))) {
          return false;
        }
      },
    });
  }
}();
